import React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import { getIntlData } from "../lib/intl";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import * as styles from "./project.module.scss";

const ProjectPage = ({ pageContext, intl }) => {
  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className={styles.sidebar}>
              <h1>{getIntlData(pageContext.name, intl.locale)}</h1>
              <p>{getIntlData(pageContext.location, intl.locale)}</p>
              <p>{getIntlData(pageContext.description, intl.locale)}</p>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div>
              {pageContext.imagesGallery.map((item, idx) => (
                <div className={styles.item} key={idx}>
                  <GatsbyImage
                    image={item.image.asset.gatsbyImageData}
                    alt={getIntlData(item.alt, intl.locale)}
                  ></GatsbyImage>

                  <p className={styles.description}>{getIntlData(pageContext.description, intl.locale)}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(ProjectPage);
